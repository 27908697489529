import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  loading: false,
  error: null,
};
export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {
    loadingSet: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const { loadingSet, setError, clearError } = loadingSlice.actions;
export default loadingSlice.reducer;
