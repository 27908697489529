import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../axios";
import { useState } from "react";

const useNotifications = () => {
  const queryClient = useQueryClient();
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);

  const fetchNotifications = async () => {
    const res = await axios.get("/notifications");
    return res.data.data;
  };

  const { data: notifications = [], isLoading } = useQuery({
    queryKey: ["notifications"],
    queryFn: fetchNotifications,
  });

  const markAsReadMutation = useMutation({
    mutationFn: async (notificationId) => {
      await axios({
        method: "POST",
        url: `/notifications/read`,
        data: { ids: notificationId },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["notifications"]);
    },
  });

  const deleteNotificationMutation = useMutation({
    mutationFn: async (notificationId) => {
      await axios({
        method: "DELETE",
        url: `/notifications/delete`,
        data: { ids: notificationId },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["notifications"]);
      setShowToast(true);
      setToastBody("Notification removed successfully");
      setSuccess(true);
    },
  });

  const markAsRead = (notificationId) => {
    markAsReadMutation.mutate(notificationId);
  };

  const deleteNotification = (notificationId) => {
    deleteNotificationMutation.mutate(notificationId);
  };

  return {
    notifications,
    isLoading,
    markAsRead,
    deleteNotification,
    toastBody,
    showToast,
    success,
    setShowToast,
  };
};

export default useNotifications;
