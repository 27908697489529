import { useQuery } from "@tanstack/react-query";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";

import axios from "../../axios";

import { scrollButton } from "../../components/Helpers/HelperFunctions";
import CustomToast from "../../reusable/CustomToast";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import { loadingSet } from "../../redux/loadingSlice";
import ServerError from "../Login/ServerError";
import useDebounce from "../../Hooks/useDebounce";
import ClinicsTable from "../../components/Table/ClinicsTable";

import AddEditMultiStepClinic from "../Modals/MultiStepClinicForm/AddEditMultiStepClinic";

const MmjClinics = () => {
  const [showModal, setShowModal] = useState(false);
  const [clinicName, setClinicName] = useState("");
  const [stateName, setStateName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [toastBody, setToastBody] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(true);

  const tableContainerRef = useRef(null);
  const searchClinicRef = useRef(null);
  const searchStateRef = useRef(null);

  const dispatch = useDispatch();
  // const { role, plan_id } = useSelector((state) => state.login.userData);

  const debouncedClinicName = useDebounce(clinicName, 500);
  const debouncedStateName = useDebounce(stateName, 500);

  const getMmjClinics = async ({ queryKey }) => {
    const [, page, clinic_name, state_name] = queryKey;
    const res = await axios(
      // `/mmjclinic/get`
      `/mmjclinic/get?name=${clinic_name}&state=${state_name}&page=${page}&limit=20`
    );
    return res.data.data;
  };

  const {
    data,
    isLoading: clinicsLoading,
    error: clinicsError,
    refetch: refetchMmjClinics,
  } = useQuery({
    queryKey: [
      "mmjclinics-list",
      currentPage,
      debouncedClinicName,
      debouncedStateName,
    ],
    queryFn: getMmjClinics,
    staleTime: 1000 * 60 * 5,
  });

  const clearFilter = useCallback(() => {
    setStateName("");
    setClinicName("");
    if (searchClinicRef.current || searchStateRef.current) {
      searchClinicRef.current.value = "";
      searchStateRef.current.value = "";
    }
  }, []);

  useEffect(() => {
    dispatch(loadingSet(clinicsLoading));
  }, [clinicsLoading, dispatch]);

  const filterHandler = useCallback((e) => {
    e.preventDefault();
    setClinicName(searchClinicRef.current.value);
    setStateName(searchStateRef.current.value);
  }, []);

  if (clinicsError) {
    return (
      <div className="error-message">
        <ServerError
          errorMessage={
            clinicsError?.response?.data?.message || "Failed to load Doctors."
          }
        />
        <Button onClick={refetchMmjClinics}>Retry</Button>
      </div>
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>MMJ Doctors</h3>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      <div className="table-settings">
        <Row className="justify-content-end align-items-center mb-2">
          <Col xl={2} md={4} lg={3} xs={6}>
            <Row className="justify-content-end me-2">
              <Button
                onClick={() => setShowModal(true)}
                className="green-btn fontweigh-500"
              >
                Add Doctors
              </Button>
            </Row>
          </Col>
        </Row>
        <Form onSubmit={filterHandler}>
          <Row className={`justify-content-between align-items-end mb-4`}>
            <Col xs={6} md={4} lg={3} xl={4}>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search doctors"
                  ref={searchClinicRef}
                />
              </InputGroup>
            </Col>
            <Col xs={6} md={4} lg={3} xl={4}>
              <InputGroup>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Search State Name"
                  ref={searchStateRef}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <Button className="mt-3 green-btn fontweigh-500" type="submit">
                Search
              </Button>
              <Button
                variant="outline-danger"
                className="fontweigh-500 mt-3 ms-2"
                onClick={clearFilter}
              >
                Clear filters
              </Button>
            </Col>
            {data?.mmj_clinics?.length
              ? scrollButton(tableContainerRef, 1350)
              : ""}
          </Row>
        </Form>
      </div>

      {showModal && (
        <AddEditMultiStepClinic
          show={showModal}
          handleClose={() => setShowModal(false)}
          refetchClinics={refetchMmjClinics}
          setShowToast={setShowToast}
          setToastBody={setToastBody}
          setSuccess={setSuccess}
        />
      )}

      {clinicsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <ClinicsTable
          clinics={data?.mmj_clinics}
          refetchClinics={refetchMmjClinics}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={data?.totalPages}
          setShowToast={setShowToast}
          setToastBody={setToastBody}
          setSuccess={setSuccess}
          tableContainerRef={tableContainerRef}
        />
      )}

      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};

export default MmjClinics;
