import React, { useMemo } from "react";
import "./MultiStepProgressBar.css";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { Container } from "react-bootstrap";

const MultiStepProgressBar = ({ currentStep }) => {
  // Calculate step percentage based on the current step
  const stepPercentage = useMemo(() => {
    switch (currentStep) {
      case 1:
        return 0;
      case 2:
        return 50;
      case 3:
        return 100;
      default:
        return 0;
    }
  }, [currentStep]);

  // Array of steps for dynamic rendering
  const steps = [1, 2, 3];

  return (
    <Container className="w-75">
      <ProgressBar percent={stepPercentage}>
        {steps.map((_, index) => (
          <Step key={index}>
            {({ accomplished }) => (
              <div
                className={`indexedStep ${
                  accomplished ? "accomplished" : ""
                } fw-bold`}
              >
                {index + 1}
              </div>
            )}
          </Step>
        ))}
      </ProgressBar>
    </Container>
  );
};

export default MultiStepProgressBar;
