import { useEffect, useState } from "react";
import axios from "../../../axios";
import { Container } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../../redux/loadingSlice";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../../Login/ServerError";
import LoadingPlaceHolder from "../../../reusable/Helper/LoadingPlaceHolder";
import ReviewsTable from "../../../components/Table/ReviewsTable";

const MyReviews = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  // Function to fetch reviews
  const getReviews = async ({ queryKey }) => {
    const [, page] = queryKey;
    const res = await axios(`/dis/reviews?page=${page}&limit=20`);
    return res.data.data;
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ["reviews-list", currentPage],
    queryFn: getReviews,
    keepPreviousData: true, // Keeps the previous data while fetching new data
    staleTime: 300000, // 5 minutes to avoid frequent refetches
  });

  // Set loading state in redux
  useEffect(() => {
    dispatch(loadingSet(isLoading));
  }, [isLoading, dispatch]);

  if (error) {
    return (
      <ServerError
        errorMessage={error?.response?.data?.message || "An error occurred"}
      />
    );
  }

  return (
    <Container fluid>
      <h3 className="green-color mt-4">My Reviews</h3>
      {isLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <ReviewsTable
          reviews={data?.dispansary_reviews}
          totalPages={data?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </Container>
  );
};

export default MyReviews;
