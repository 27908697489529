import React from "react";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

// core styles
import "./scss/volt.scss";

// vendor styles
import "react-datetime/css/react-datetime.css";

//Custom components
import ScrollToTop from "./components/ScrollToTop";
import store from "./redux/store";
import { BASE_URL } from "./config";
import App from "./App";
import NoInternetConnection from "./NoInternetConnection";
import { HelmetProvider } from "react-helmet-async";

axios.defaults.baseURL = BASE_URL;

const container = document.getElementById("root");
const root = createRoot(container);
const queryClient = new QueryClient();

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <NoInternetConnection>
            <App />
          </NoInternetConnection>
        </HelmetProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </Provider>
);
