import {
  CardHeader,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "react-bootstrap";
// import GallerySlider from "../GallerySlider";

// import LocationGoogleMap from "../../Explore/LocationGoogleMap";
import WorkHours from "../Dispensary/WorkHours";
import SocialMedia from "../Dispensary/SocialMedia";

const ClinicProfile = ({ clinicData }) => {
  if (!clinicData) {
    return (
      <Container>
        <Row>
          <Col className="text-center">No Data Avialable....</Col>
        </Row>
      </Container>
    );
  }
  // const disData = [
  //   {
  //     id: clinicData?.id,
  //     latitude: clinicData.latitude,
  //     longitude: clinicData.longitude,
  //     logo: clinicData.logo,
  //   },
  // ];
  // const center = {
  //   lat: Number(clinicData.latitude),
  //   lng: Number(clinicData.longitude),
  // };
  return (
    <Container fluid>
      <Row>
        {/* {clinicData?.gallery.length > 0 && (
          <Col className="mb-4">
            <GallerySlider heading="Gallery" data={dispensary?.gallery} />
          </Col>
        )} */}
        <Col md={6} className="mb-4">
          <Card className="mb-2">
            <CardHeader className="fontweigh-700">Doctor Address</CardHeader>
            <CardBody>
              <Row>
                <Col className="fw-bold green-color">Street</Col>
                <Col>{clinicData?.street}</Col>
              </Row>
              <hr />
              <Row>
                <Col className="fw-bold green-color">City</Col>
                <Col>{clinicData?.city}</Col>
              </Row>
              <hr />
              <Row>
                <Col className="fw-bold green-color">State</Col>
                <Col>{clinicData?.state?.state_name}</Col>
              </Row>
              <hr />
              <Row>
                <Col className="fw-bold green-color">Zip Code</Col>
                <Col>{clinicData?.postal_code}</Col>
              </Row>
              <hr />
              <Row>
                <Col className="fw-bold green-color">Country</Col>
                <Col>{clinicData?.country}</Col>
              </Row>
            </CardBody>
          </Card>
          {clinicData?.social_media.length ? (
            <SocialMedia socialMedia={clinicData?.social_media} />
          ) : (
            ""
          )}
        </Col>

        <Col md={6} className="mb-4">
          <WorkHours workingHoursData={clinicData?.work_hours} />
        </Col>

        {/* <Col className="mb-4">
          <LocationGoogleMap data={disData} center={center} />
        </Col> */}
      </Row>
    </Container>
  );
};
export default ClinicProfile;
