import React from "react";
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Image, Row } from "react-bootstrap";
import { useDropzone } from "react-dropzone";

const MultImageUploader = ({ images, setImages }) => {
  const onDrop = (acceptedFiles) => {
    const newImages = acceptedFiles.map((file) => ({
      gallery: file,
      gallery_image: URL.createObjectURL(file),
    }));

    setImages((prevImages) => {
      if (prevImages) {
        return [...prevImages, ...newImages];
      } else return [...newImages];
    });
  };

  const removeImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    onDrop,
  });

  return (
    <Row className="justify-content-center mb-4">
      {images?.map((image, index) => (
        <Col
          lg={2}
          md={3}
          sm={4}
          xs={6}
          key={image.gallery_image}
          style={{
            position: "relative",
            display: "inline-block",
            marginRight: "8px",
          }}
          className="mb-2"
        >
          <Image
            className="border border-success"
            src={image?.gallery_image}
            alt={`Preview ${index + 1}`}
            style={{
              width: "12em",
              height: "12em",
            }}
          />

          <FontAwesomeIcon
            icon={faWindowClose}
            className="text-danger"
            onClick={() => removeImage(index)}
            style={{
              position: "absolute",
              top: "0",
              right: "0",
              border: "none",
              cursor: "pointer",
            }}
          />
        </Col>
      ))}
      <Col
        lg={2}
        xs={6}
        {...getRootProps()}
        style={{
          marginBottom: "16px",
          border: "1px dashed #ccc",
          padding: "16px",
          textAlign: "center",
        }}
      >
        <input {...getInputProps()} />
        Add Image
      </Col>
    </Row>
  );
};

export default MultImageUploader;
