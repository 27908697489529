// libararies
import { useQuery } from "@tanstack/react-query";
import {
  Col,
  Row,
  Button,
  InputGroup,
  Form,
  FormGroup,
  FormControl,
} from "react-bootstrap";

// Componenents
import axios from "../../axios";

import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import EmailMarketingTable from "../../components/Table/EmailMarketingTable";
import AddEmailInstance from "./AddEmailInstance";

const EmailMarketing = () => {
  // states
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchState, setSearchState] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  //dispatch
  const dispatch = useDispatch();

  //Functions
  const getEmailSentData = async ({ queryKey }) => {
    const [, page, state] = queryKey;
    const res = await axios(
      `/get/email/marketing/list?limit=20&page=${page}&state=${state}`
    );
    return res.data.data;
  };

  const {
    data,
    isLoading: usersLoading,
    error: usersError,
    refetch: refetchEmailSentData,
  } = useQuery({
    queryKey: ["email-marketing-list", currentPage, searchState],
    queryFn: getEmailSentData,
  });
  const handleInputChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);
  //useEffects
  // Trigger API call on form submission
  const searchHandler = useCallback(
    (e) => {
      e.preventDefault();
      setSearchState(searchTerm);
    },
    [searchTerm]
  );
  const clearFilters = useCallback(() => {
    setSearchState("");
    setSearchTerm("");
  }, []);
  useEffect(() => {
    dispatch(loadingSet(usersLoading));
  }, [usersLoading, dispatch]);

  if (usersError) {
    return (
      <ServerError
        errorMessage={usersError?.response?.data?.message || usersError}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>Email marketing</h3>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      <div className="table-settings mb-4">
        <Row className="justify-content-end align-items-center mb-4">
          {/* <Col xs={6} md={4} lg={3} xl={4}>
            <InputGroup>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <Form.Control
                type="text"
                placeholder="Search User Name"
                onChange={
                  (event) => {}
                  // setFilteredData(handleSearchChange(event, users, "name"))
                }
              />
            </InputGroup>
          </Col> */}
          <Col xl={2} md={4} lg={3} xs={6}>
            <Row className="justify-content-end me-2">
              <Button
                onClick={() => setShowModal(true)}
                className="green-btn fontweigh-500"
              >
                Create Instance
              </Button>
            </Row>
          </Col>
        </Row>
        <Form onSubmit={searchHandler}>
          <Row
            className={`align-items-baseline justify-content-between ${
              !data?.users?.length && "mb-3"
            }`}
          >
            <Col sm={6} md={4} className="mb-2">
              <FormGroup>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <FormControl
                    type="text"
                    placeholder="Search By State"
                    value={searchTerm}
                    onChange={handleInputChange}
                  />
                </InputGroup>
              </FormGroup>
            </Col>

            <Col sm={6} md={4} className="justify-content-end d-flex">
              <Button className="mt-3 green-btn fontweigh-500" type="submit">
                Search
              </Button>
              <Button
                variant="outline-danger"
                className="fontweigh-500 mt-3 ms-2"
                onClick={clearFilters}
              >
                Clear filters
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <AddEmailInstance
        show={showModal}
        handleClose={() => setShowModal(false)}
        refetchEmailSentData={refetchEmailSentData}
      />
      {usersLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <EmailMarketingTable
          EmailSentData={data?.dispensaries}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={data?.totalPages}
          refetchEmailSentData={refetchEmailSentData}
        />
      )}
    </>
  );
};
export default EmailMarketing;
