import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { loadingSet } from "../../redux/loadingSlice";
import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import { scrollButton } from "../../components/Helpers/HelperFunctions";
import OmcLeadsTable from "../../components/Table/OmcLeadsTable";
import Select from "react-select";

const OmcLeads = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [leadType, setLeadType] = useState({
    label: "Appointment",
    value: "appointment",
  });
  const [data, setData] = useState([]);
  const [omcLeadsError, setOMCLeadsError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tableContainerRef = useRef(null);

  const dispatch = useDispatch();
  const omcLeadsLoading = useSelector((state) => state.loading.loading);

  // Function to format dates
  const formatDate = useCallback((date) => {
    if (!date) return "";
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  }, []);

  // Function to fetch OMC leads
  const getOMCLeads = useCallback(async () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    dispatch(loadingSet(true));
    try {
      const res = await axios.get(
        `/mmj/leads?start_date=${formattedStartDate}&end_date=${formattedEndDate}&limit=20&page=${currentPage}&lead_type=${leadType.value}`
      );
      setData(res.data.data);
    } catch (err) {
      setOMCLeadsError(err);
    } finally {
      dispatch(loadingSet(false));
    }
  }, [dispatch, formatDate, startDate, endDate, currentPage, leadType]);

  // Fetch data when dependencies change
  useEffect(() => {
    getOMCLeads();
  }, [getOMCLeads]);

  // Function to clear filters
  const clearFilters = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setCurrentPage(1);
  };

  if (omcLeadsError) {
    return (
      <ServerError
        errorMessage={
          omcLeadsError.response?.data?.message || "An error occurred"
        }
      />
    );
  }
  const LeadTypeOptions = [
    { label: "Appointment", value: "appointment" },
    { label: "Card", value: "card" },
  ];

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>OMC Leads</h3>
        </div>
      </div>

      <div className="table-settings">
        <Row className="align-items-end">
          <Col md={3} xs={4}>
            <Form.Label>
              <h5>Start Date</h5>
            </Form.Label>
            <DatePicker
              selected={startDate}
              onChange={setStartDate}
              dateFormat="yyyy/MM/dd"
              className="form-control"
            />
          </Col>
          <Col md={3} xs={4}>
            <Form.Label>
              <h5>End Date</h5>
            </Form.Label>
            <DatePicker
              selected={endDate}
              onChange={setEndDate}
              dateFormat="yyyy/MM/dd"
              className="form-control"
              minDate={startDate}
            />
          </Col>
          <Col md={3} xs={4}>
            <Form.Label>
              <h5>Lead Type</h5>
            </Form.Label>
            <Select
              name="lead_type"
              value={leadType}
              options={LeadTypeOptions}
              onChange={(e) => setLeadType(e)}
            />
          </Col>
          <Col md={3}>
            <Button
              variant="outline-danger"
              className="fontweigh-500 mt-3 ms-2"
              onClick={clearFilters}
            >
              Clear filters
            </Button>
          </Col>
        </Row>
        <Row
          className={`justify-content-end align-items-center ${
            data?.medicalCardLead?.length ? "mb-0" : "mb-4"
          }`}
        >
          {data?.medicalCardLead?.length &&
            scrollButton(tableContainerRef, 800)}
        </Row>
      </div>

      {omcLeadsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <OmcLeadsTable
          omcLeadsData={data?.mmj_leads}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={data?.totalPages}
          tableContainerRef={tableContainerRef}
        />
      )}
    </>
  );
};

export default OmcLeads;
