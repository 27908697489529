// BlogComments component using React Query v5 format
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import axios from "../../axios";
import { loadingSet } from "../../redux/loadingSlice";
import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import BlogCommentsTable from "../../components/Table/BlogCommentsTable";

const BlogComments = () => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);

  // Redux dispatch
  const dispatch = useDispatch();

  // Function to fetch blog comments
  const getBlogComments = async ({ queryKey }) => {
    const page = queryKey[1];
    const res = await axios(`/blog/comments?page=${page}&limit=20`);
    return res.data.data;
  };

  const {
    data,
    isLoading: blogCommentsLoading,
    error: blogCommentsError,
  } = useQuery({
    queryKey: ["blog-comments-list", currentPage],
    queryFn: getBlogComments,
    keepPreviousData: true,
  });

  useEffect(() => {
    dispatch(loadingSet(blogCommentsLoading));
  }, [blogCommentsLoading, dispatch]);

  // Handle error
  if (blogCommentsError) {
    return (
      <ServerError
        errorMessage={
          blogCommentsError?.response?.data?.message ||
          blogCommentsError.message
        }
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h3>Blog Comments</h3>
        </div>
      </div>

      {blogCommentsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <BlogCommentsTable
          blogComments={data?.blogComments}
          totalPages={data?.totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};

export default BlogComments;
