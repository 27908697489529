import React from "react";
import { Image } from "react-bootstrap";
import logo from "../assets/img/wtmIcon.png";

export default (props) => {
  const { show } = props;

  return (
    <div
      className={`preloader loader-back flex-column justify-content-center align-items-center ${
        show ? "" : "show"
      }`}
    >
      <Image
        className="loader-element animate__animated animate__jackInTheBox"
        src={logo}
        alt="logo"
        height={40}
      />
    </div>
  );
};
