import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import DispProdWithScroller from "./Helper/DispProdWithScroller";
import DispProds from "./Helper/DispProds";
import useCart from "../Hooks/UseCart";

const DispensarywiseProducts = ({
  dispensaries,
  categoryId,
  categoryName,
  setShowToast,
  setSuccess,
  setToastBody,
}) => {
  const { addToCart } = useCart();

  const { dis_id } = useParams();

  // const cards = (dispensary) =>
  //   dispensary?.products?.map((product) => (
  //     <Col xl={3} lg={4} md={5} sm={6} key={product.id}>
  //       <ProductCard
  //         setShowToast={setShowToast}
  //         setToastBody={setToastBody}
  //         setSuccess={setSuccess}
  //         product={product}
  //         dis={dispensary}
  //         addToCart={addToCart}
  //       />
  //     </Col>
  //   ));

  return (
    <>
      {" "}
      {dis_id ? (
        <DispProds
          setShowToast={setShowToast}
          setSuccess={setSuccess}
          setToastBody={setToastBody}
          addToCart={addToCart}
        />
      ) : (
        <DispProdWithScroller
          dispensaries={dispensaries}
          categoryId={categoryId}
          categoryName={categoryName}
          addToCart={addToCart}
          setShowToast={setShowToast}
          setSuccess={setSuccess}
          setToastBody={setToastBody}
        />
      )}
      {/* {dispensaries?.map((dispensary) => (
        <Row className="mb-5">
          <div className="text-right" sm={2} xs={4}>
            <Link
              className="black-color fw-bold text-end me-4"
              to={{
                pathname: `${
                  Routes.ProductsByCategory.path
                }/${categoryName.replaceAll(" ", "-")}/${categoryId}${
                  dispensary.name && dispensary.id
                    ? `/${dispensary.name.replaceAll(" ", "-")}/${
                        dispensary.id
                      }`
                    : ""
                }`,
              }}
            >
              {dispensary.products.length > 4 && "See All"}
            </Link>
          </div>
          <h3 className="fs-5 fw-bold mb-0">{dispensary?.name}</h3>
          {dispensary?.distance ? (
            <p className="mb-1 fs-7">
              {dispensary.distance.toFixed(1)} mi away
            </p>
          ) : (
            ""
          )}
          {showSlider(dispensary.products) ? (
            <Slider {...settings} className="card-slider p-2">
              {cards(dispensary)}
            </Slider>
          ) : (
            <Row>{cards(dispensary)}</Row>
          )}
        </Row>
      ))} */}
    </>
  );
};
export default DispensarywiseProducts;
