import { faHeart, faStar, faStarHalf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "../../../../axios";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Routes } from "../../../../routes";
import verfiedBadge from "../../../../assets/img/verifiedBadge.webp";
import dummyCoverImage from "../../../../assets/img/dummy_cover_image.webp";
import tableViewHeroImage from "../../../../assets/img/tablet_view_hero.webp";
import { loadingSet } from "../../../../redux/loadingSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Banner = ({
  coverImage,
  logo,
  name,
  averageRating,
  claimText,
  dis_id,
  setShowToast,
  setToastBody,
  setSuccess,
  is_premium,
  type,
  isFavoriteDisp,
  refetchDisp,
  is_medical,
  is_recreational,
}) => {
  const userData = useSelector((state) => state.login.userData);
  const loading = useSelector((state) => state.loading.loading);
  const history = useHistory();

  //dispatch
  const dispatch = useDispatch();

  const claimHandler = async () => {
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "post",
        url: "/claim/partner",
        data: { user_id: userData.id, dis_id: dis_id },
      });
      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  const handleToggleFav = async (id, is_favorite) => {
    if (!userData.role) {
      history.push({
        pathname: Routes.Signin.path,
        state: { type: "dispensary_card" },
      });
      return;
    }
    dispatch(loadingSet(true));
    try {
      let res;
      if (is_favorite) {
        res = await axios({
          method: "DELETE",
          url: `/remove/wishlist/dispensary/${id}`,
        });
      } else {
        res = await axios({
          method: "POST",
          url: `/add/wishlist/dispensary`,
          data: { dispensary_id: id },
        });
      }
      refetchDisp();

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  const disabled = claimText === "Claimed" || userData.role === "admin";

  return (
    <Container
      fluid
      className="p-0 mb-4 overflow-hidden"
      style={{ height: "24rem" }}
    >
      <Card
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${
            loading ? dummyCoverImage : coverImage
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "100%",
          flexDirection: "row",
          justifyContent: "flex-end",
          position: "relative",
        }}
      >
        {" "}
        {(is_medical || is_recreational) && (
          <Badge
            bg="light"
            className="py-3 px-2 position-absolute bottom-0 start-0 fs-7 text-dark"
          >
            {is_medical ? "MEDICAL" : ""}{" "}
            {is_medical && is_recreational ? "&" : ""}{" "}
            {is_recreational ? "RECREATIONAL" : ""}
          </Badge>
        )}
        <Row className="content-left d-flex py-2">
          <Col className="d-flex flex-wrap align-items-center">
            {logo && (
              <Image
                src={logo}
                alt="Logo"
                height={70}
                width={70}
                className="cat-card rounded-circle"
              />
            )}
            <h1 className="ms-4 mb-0 fs-6 text-white fw-bolder">{name}</h1>
            {averageRating ? (
              <div className="ms-4 ">
                {[...Array(Math.round(averageRating))].map((_, i) => (
                  <FontAwesomeIcon icon={faStar} color="#fff" />
                ))}
                {averageRating > Math.round(averageRating) && (
                  <FontAwesomeIcon icon={faStarHalf} color="#fff" />
                )}{" "}
                <span className="text-white">({averageRating}/5)</span>
              </div>
            ) : (
              ""
            )}
            {is_premium ? (
              <Image
                // className="my-3"
                src={verfiedBadge}
                alt="verified Image"
                width={35}
                height={30}
              />
            ) : (
              ""
            )}{" "}
          </Col>
        </Row>
        {!(type === "blog" || type === "brand" || type === "doctors") && (
          <Row className="d-flex align-items-end">
            <Col className="text-end m-3">
              {(userData.role === "user" || !userData?.role) && (
                <Button
                  variant="link"
                  onClick={() => handleToggleFav(dis_id, isFavoriteDisp)}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faHeart}
                    color={isFavoriteDisp ? "#ff9f89db" : "#fff"}
                    className="fs-4"
                  />
                </Button>
              )}
              {/* </Col> */}
              {!type && (
                // <Col className="text-end m-3">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id="button-tooltip-2">
                      Own this dispensary ?
                    </Tooltip>
                  }
                >
                  {userData.role === "partner" ||
                  userData.role === "admin" ||
                  claimText === "Claimed" ? (
                    <Button
                      className="green-btn"
                      onClick={claimHandler}
                      disabled={claimText === "Claimed"}
                    >
                      {claimText}
                    </Button>
                  ) : (
                    <Button
                      as={Link}
                      disabled={disabled}
                      to={{
                        pathname: Routes.Signup.path,
                        state: {
                          type: "partner",
                          is_claimed: true,
                          dis_id: dis_id,
                        },
                      }}
                      className="text-white green-btn"
                    >
                      {claimText}
                    </Button>
                  )}
                </OverlayTrigger>
              )}
            </Col>
          </Row>
        )}
      </Card>
    </Container>
  );
};
export default Banner;
