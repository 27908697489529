//libraries
import { Card, CardBody, Container, Image, Row } from "react-bootstrap";

import { Link } from "react-router-dom";
//Routes
import { Routes } from "../../../../routes";
import { useMediaQuery } from "react-responsive";
import Slider from "react-slick";

const ShopByCategory = ({ searchByCatg, type, disName, dispId }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Media queries
  const isMd = useMediaQuery({ query: "(max-width: 992px)" });
  const isXs = useMediaQuery({ query: "(max-width: 576px)" });

  // Determine the condition for showing the slider
  const showSlider =
    (isXs && searchByCatg.length > 1) ||
    (isMd && searchByCatg.length > 3) ||
    (!isMd && searchByCatg.length > 4);

  const cards = searchByCatg?.map((cat) => (
    // <Col key={cat.name} className="d-flex justify-content-center my-3">
    <Card
      className="cat-card mb-4"
      as={Link}
      to={{
        pathname: `${Routes.ProductsByCategory.path}/${cat.name?.replaceAll(
          " ",
          "-"
        )}/${cat.id}${
          disName && dispId ? `/${disName?.replaceAll(" ", "-")}/${dispId}` : ""
        }`,
      }}
      // state: { id: cat.id, name: cat.name, type: "products" },
    >
      <Image
        className="cat-card-image"
        src={cat.cover_image}
        alt="category cover"
        height={150}
        width={150}
        style={{ objectFit: "cover" }}
      />

      <CardBody className="cat-card-heading">{cat.name}</CardBody>
    </Card>
    // </Col>
  ));
  return (
    <Container fluid className="section-container">
      {!dispId && (
        <h1 className="pb-3 text-center fs-4 fw-bolder">SHOP BY CATEGORY</h1>
      )}
      {showSlider ? (
        <Slider {...settings} className="card-slider">
          {cards}
          {/* {searchByCatg?.map((cat) => (
          <div key={cat.name} className="d-flex justify-content-center my-3">
            <Card
              className="cat-card"
              as={Link}
              to={{
                pathname: `${
                  Routes.ProductsByCategory.path
                }/${cat.name?.replaceAll(" ", "-")}/${cat.id}${
                  disName && dispId
                    ? `/${disName?.replaceAll(" ", "-")}/${dispId}`
                    : ""
                }`,
              }}
              // state: { id: cat.id, name: cat.name, type: "products" },
            >
              <Image
                className="cat-card-image"
                src={cat.cover_image}
                alt="category cover"
                height={150}
                width={150}
                style={{ objectFit: "cover" }}
              />

              <CardBody className="cat-card-heading">{cat.name}</CardBody>
            </Card>
          </div>
        ))} */}
        </Slider>
      ) : (
        <Row>{cards}</Row>
      )}
    </Container>
  );
};
export default ShopByCategory;
