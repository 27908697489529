import {
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Image,
  Badge,
} from "react-bootstrap";
import "./Card.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingBag, faStore } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import verifiedBadge from "../../assets/img/verifiedBadge.webp";

const ProductCard = ({
  setShowToast,
  setToastBody,
  setSuccess,
  product,
  addToCart,
  dispensary,
  brand,
  brand_id,
}) => {
  const disabled = !dispensary?.can_add_to_cart;
  const productDetailsLink = {
    pathname: `${Routes.productDetails.path}/${product.name.replaceAll(
      " ",
      "-"
    )}/${product?.id}/${dispensary?.id}`,
    // state: {
    //   id: product?.id,
    //   dis_id: product.dispensary?.id,
    // },
  };
  return (
    <Card className="product-card prod-card m-1 my-3">
      {product?.coupons?.length ? (
        <Badge className="coupon-badge px-4 py-1" bg="success">
          {product.coupons[0].percentage
            ? `${product.coupons[0].percentage}% OFF`
            : product.coupons[0].amount_fixed
            ? `${product.coupons[0].amount_fixed} OFF`
            : product.coupons[0].quantity_free
            ? `BUY ${product.coupons[0].quantity_required} GET ${product.coupons[0].quantity_free} FREE`
            : ""}{" "}
          {product?.coupons?.length > 0
            ? ` +${product.coupons.length} offer${
                product.coupons.length > 1 ? "s" : ""
              } `
            : ""}
        </Badge>
      ) : (
        ""
      )}
      <Link className="card-image" to={productDetailsLink}>
        <Card.Img
          className="img-fluid "
          variant="top"
          src={product.image}
          loading="lazy"
          alt="product-image"
          // fluid
        />
      </Link>
      <Card.Body
        as={Link}
        to={productDetailsLink}
        className="text-center py-0 px-3 mt-3"
      >
        <Card.Title className="title mb-1">{product.name}</Card.Title>
        <Card.Text className="fw-bold fs-8 text-dark">
          {(product.brand?.name || brand) && (
            <Link
              className="mb-1 text-decoration-none d-block"
              to={{
                pathname: `${Routes.ProductsByBrand.path}/${
                  product.brand?.name?.replaceAll(" ", "-") ||
                  (brand && brand?.replaceAll(" ", "-"))
                }/${product.brand?.id || brand_id}`,
                // state: {
                //   id: product.brand?.id,
                //   name: product.brand?.name || brand,
                //   type: "brand",
                // },
              }}
            >
              by <span className=" ">{product.brand?.name || brand}</span>
            </Link>
          )}
          {dispensary?.name && (
            <Link
              className=" text-decoration-none"
              to={{
                pathname: `${Routes.dispensaryDetails.path}/${dispensary?.id}/${dispensary?.name}/profile`,
                state: { id: dispensary?.id },
              }}
            >
              {" "}
              <FontAwesomeIcon icon={faStore} className="green-color" />{" "}
              <span className="link-text">{dispensary?.name}</span>
              {dispensary?.is_premium && (
                <Image
                  className="d-inline verified-badge"
                  src={verifiedBadge}
                  alt="verified Image"
                  width={45}
                  height={40}
                />
              )}{" "}
            </Link>
          )}
          <p className="fs-7 mb-1">
            <span className="text-muted text-decoration-line-through">
              ${product.regular_price}
            </span>{" "}
            <span className="green-color text-decoration-underline fw-bolder">
              ${product.sale_price}
            </span>
          </p>
          {/* {product.short_description} */}
        </Card.Text>
      </Card.Body>
      <OverlayTrigger
        placement="top"
        overlay={
          disabled ? (
            <Tooltip id="button-tooltip">
              Order from this dispensary is not allowed
            </Tooltip>
          ) : (
            <></>
          )
        }
      >
        <div className="text-center mb-3">
          <Button
            onClick={() =>
              addToCart(
                [product.id],
                [dispensary?.id],
                [1],
                setShowToast,
                setToastBody,
                setSuccess
                // product?.dispensary?.id
              )
            }
            disabled={disabled}
            className={`green-btn py-1 px-2 fontweigh-500`}
          >
            <FontAwesomeIcon icon={faShoppingBag} /> Add To Cart
          </Button>
        </div>
      </OverlayTrigger>
    </Card>
  );
};

export default ProductCard;
