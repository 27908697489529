import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Image, Nav } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

const NavItem = (props) => {
  const {
    title,
    link,
    external,
    target,
    icon,
    image,
    badgeText,
    setShow,
    badgeBg = "secondary",
    badgeColor = "primary",
  } = props;
  const classNames = badgeText
    ? "d-flex justify-content-start align-items-center justify-content-between"
    : "";
  const location = useLocation();
  const { pathname } = location;
  const navItemClassName = link === pathname ? "active" : "";
  const linkProps = external ? { href: link } : { as: Link, to: link };

  return (
    <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
      <Nav.Link {...linkProps} target={target} className={classNames}>
        <span>
          {icon ? (
            <span className="sidebar-icon">
              <FontAwesomeIcon icon={icon} />{" "}
            </span>
          ) : null}
          {image ? (
            <Image
              src={image}
              alt="weed to me logo"
              width={25}
              height={30}
              className="sidebar-icon svg-icon me-1"
            />
          ) : null}

          <span className="sidebar-text">{title}</span>
        </span>
        {badgeText ? (
          <Badge
            pill
            bg={badgeBg}
            text={badgeColor}
            className="badge-md notification-count ms-2"
          >
            {badgeText}
          </Badge>
        ) : null}
      </Nav.Link>
    </Nav.Item>
  );
};
export default NavItem;
