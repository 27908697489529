import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  dispByState: [],
};
export const homePageSlice = createSlice({
  name: "dispensaries_by_state",
  initialState,
  reducers: {
    dispByStateSet: (state, action) => {
      state.dispByState = action.payload;
    },
  },
});
export const { dispByStateSet } = homePageSlice.actions;
export default homePageSlice.reducer;
