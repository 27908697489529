import React, { useEffect, useRef, useState, useCallback } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../axios";
import { loadingSet } from "../../redux/loadingSlice";
import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import { scrollButton } from "../../components/Helpers/HelperFunctions";
import DispAdvertisementLeadsTable from "../../components/Table/DispAdvertisementLeadsTable";

const DispAdvertisementLeads = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [data, setData] = useState([]);
  const [disLeadsError, setDisLeadsError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const tableContainerRef = useRef(null);

  const dispatch = useDispatch();
  const disLeadsLoading = useSelector((state) => state.loading.loading);

  // Helper function to format dates
  const formatDate = useCallback((date) => {
    return date
      ? `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`
      : "";
  }, []);

  // Function to fetch dispensary leads
  const getDispensaryLeads = useCallback(async () => {
    const formattedStartDate = formatDate(startDate);
    const formattedEndDate = formatDate(endDate);

    dispatch(loadingSet(true));
    try {
      const res = await axios.get(
        `/advertisement/leads/get?start_date=${formattedStartDate}&end_date=${formattedEndDate}&limit=20&page=${currentPage}`
      );
      setData(res.data.data);
    } catch (err) {
      setDisLeadsError(err);
    } finally {
      dispatch(loadingSet(false));
    }
  }, [dispatch, formatDate, startDate, endDate, currentPage]);

  // Fetch data when dependencies change
  useEffect(() => {
    getDispensaryLeads();
  }, [getDispensaryLeads]);

  // Function to clear filters
  const clearFilters = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setCurrentPage(1);
  };

  if (disLeadsError) {
    return (
      <ServerError
        errorMessage={
          disLeadsError.response?.data?.message || "An error occurred"
        }
      />
    );
  }

  return (
    <>
      <div className="d-block my-4">
        <h3>Advertisement Leads</h3>
      </div>

      <div className="table-settings">
        <Row className="align-items-end">
          <Col lg={3} sm={4} className="mb-2">
            <Form.Label className="d-block">
              <h5>Start Date</h5>
            </Form.Label>
            <DatePicker
              selected={startDate}
              onChange={setStartDate}
              dateFormat="yyyy/MM/dd"
              className="form-control"
            />
          </Col>
          <Col lg={3} sm={4} className="mb-2">
            <Form.Label className="d-block">
              <h5>End Date</h5>
            </Form.Label>
            <DatePicker
              selected={endDate}
              onChange={setEndDate}
              dateFormat="yyyy/MM/dd"
              className="form-control"
              minDate={startDate}
            />
          </Col>
          <Col sm={4}>
            <Button
              variant="outline-danger"
              className="fontweigh-500 mt-3 ms-2"
              onClick={clearFilters}
            >
              Clear filters
            </Button>
          </Col>
        </Row>
        <Row
          className={`justify-content-end align-items-center ${
            data?.advertisement_lead?.length ? "mb-0" : "mb-4"
          }`}
        >
          {data?.advertisement_lead?.length
            ? scrollButton(tableContainerRef, 800)
            : ""}
        </Row>
      </div>

      {disLeadsLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <DispAdvertisementLeadsTable
          disLeadsData={data?.advertisement_lead}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={data?.totalPages}
          tableContainerRef={tableContainerRef}
        />
      )}
    </>
  );
};

export default DispAdvertisementLeads;
