import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { locationSet } from "../redux/LogIn/loginSlice";

const useUserLocation = () => {
  const dispatch = useDispatch();
  const userLocation = useSelector((state) => state.login.location);

  useEffect(() => {
    const fetchLocation = async () => {
      if (!userLocation.lat || !userLocation.long) return;

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${userLocation.lat},${userLocation.long}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;

      try {
        const response = await fetch(url);
        const data = await response.json();

        if (data.status === "OK") {
          const stateName = data.results[0]?.address_components.find(
            (component) =>
              component.types.includes("administrative_area_level_1")
          );
          if (stateName) {
            dispatch(
              locationSet({
                ...userLocation,
                state: stateName.long_name,
              })
            );
          } else {
            console.warn("State information not found in geolocation data.");
          }
        } else {
          console.warn("Reverse geolocation request failed: ", data.status);
        }
      } catch (error) {
        console.error("Error fetching location data: ", error);
      }
    };

    fetchLocation();
  }, [userLocation.lat, userLocation.long, dispatch]);
};

export default useUserLocation;
