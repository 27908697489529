import { useParams } from "react-router-dom";

import { useState } from "react";

import ClinicProfile from "./ClinicProfile";
import CustomToast from "../../../reusable/CustomToast";
import ClinicAppointment from "./ClinicAppointment";

const ClinicDetailsBody = ({ clinicData }) => {
  const { type } = useParams();
  //customtoast states
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);

  return (
    <>
      {type === "profile" && <ClinicProfile clinicData={clinicData} />}

      {type === "appointment" && <ClinicAppointment clinicData={clinicData} />}

      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default ClinicDetailsBody;
