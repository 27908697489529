// src/components/GlobalMetadata.jsx
import React from "react";
import { Helmet } from "react-helmet-async";

const GlobalMetadata = ({ children }) => {
  const defaultTitle = "WeedToMe | Cannabis Marketplace";
  const defaultDescription =
    "Explore premium cannabis products, dispensaries, and blogs. Order online or visit a store near you.";
  const defaultOgImage = "/favicon.png";

  return (
    <>
      <Helmet>
        <title>{defaultTitle}</title>
        <meta name="description" content={defaultDescription} />
        <meta property="og:title" content={defaultTitle} />
        <meta property="og:description" content={defaultDescription} />
        <meta property="og:image" content={defaultOgImage} />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={defaultTitle} />
        <meta name="twitter:description" content={defaultDescription} />
        <meta name="twitter:image" content={defaultOgImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      {children}
    </>
  );
};

export default GlobalMetadata;
