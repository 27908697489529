import React from "react";
import moment from "moment";
import { Accordion, Col, Row } from "react-bootstrap";

const WorkHours = ({ workingHoursData }) => {
  const formatTime = (time) => {
    if (time?.includes("-")) {
      const [startTime, endTime] = time.split("-");
      return (
        <span>
          {moment(startTime, "HH:mm").format("hh:mm A")} -{" "}
          {moment(endTime, "HH:mm").format("hh:mm A")}
        </span>
      );
    }
    return <span>{time?.split("_").join(" ")}</span>;
  };

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const today = moment().format("dddd");
  const currentDay = today.toLocaleLowerCase();

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <div className="green-color fontweigh-700">
            {today} : {formatTime(workingHoursData[currentDay])}
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {days.map((day, index) => (
            <React.Fragment key={index}>
              <Row
              // className={
              //   today === day
              //     ? "text-dark fontweigh-700"
              //     : "text-dark fw-bold"
              // }
              >
                <Col className="green-color">{day}</Col>
                <Col>{formatTime(workingHoursData?.[day.toLowerCase()])}</Col>
              </Row>
              {index !== days.length - 1 && <hr />}
            </React.Fragment>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default WorkHours;
