import { StandaloneSearchBox } from "@react-google-maps/api";
import { useRef } from "react";

const DispPlaceAutoComplete = ({ setNewLocation, newLocation }) => {
  const inputRef = useRef();

  const handlePlaceChange = () => {
    const [place] = inputRef.current.getPlaces();

    if (place) {
      const address = place.address_components;
      const address_line1 =
        address.filter((ad) => ad.types[0] === "street_number")[0]?.long_name ||
        "";
      const address_line2 =
        address.filter((ad) => ad.types[0] === "route")[0]?.long_name || "";
      const city =
        address.filter((ad) => ad.types[0] === "locality")[0]?.long_name || "";
      const state =
        address.filter((ad) => ad.types[0] === "administrative_area_level_1")[0]
          ?.long_name || "";
      const zip_code =
        address.filter((ad) => ad.types[0] === "postal_code")[0]?.long_name ||
        "";
      const lat = place.geometry.location.lat();
      const long = place.geometry.location.lng();
      setNewLocation({
        street_address: `${address_line1} ${address_line2}`,
        city,
        state,
        lat,
        long,
        zip_code,
      });
    } else
      setNewLocation({
        street_address: "",
        city: "",
        state: "",
        lat: "",
        long: "",
        zip_code: "",
      });
  };

  return (
    <div className="mb-2 me-2">
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChange}
      >
        <input
          type="text"
          className="form-control"
          value={newLocation.street_address}
          onChange={(e) => setNewLocation(e.target.value)}
          placeholder="Enter Street Address"
        />
      </StandaloneSearchBox>
    </div>
  );
};

export default DispPlaceAutoComplete;
