import {
  faFacebook,
  faInstagram,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  InstapaperShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";

const ShareButton = ({ url, show, handleClose }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500); // Reset copied state after 1.5 seconds
  };
  return (
    <Modal
      show={show}
      size="md"
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>Share</Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <FacebookShareButton url={url} className="p-2 fs-2">
              <FontAwesomeIcon icon={faFacebook} />
            </FacebookShareButton>
          </Col>
          <Col>
            <TwitterShareButton url={url} className="p-2 fs-2">
              <FontAwesomeIcon icon={faTwitter} />
            </TwitterShareButton>
          </Col>
          <Col>
            <WhatsappShareButton url={url} className="p-2 fs-2">
              <FontAwesomeIcon icon={faWhatsapp} />
            </WhatsappShareButton>
          </Col>
          <Col>
            {" "}
            <InstapaperShareButton url={url} className="p-2 fs-2">
              <FontAwesomeIcon icon={faInstagram} />
            </InstapaperShareButton>
          </Col>
          <Col>
            <CopyToClipboard text={url} onCopy={handleCopy}>
              <Button variant="light" className="p-2 ">
                <FontAwesomeIcon className="fs-3" icon={faCopy} />
                <span>{copied ? " Copied!" : " Copy"}</span>
              </Button>
            </CopyToClipboard>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>Close</Button>
      </Modal.Footer>
    </Modal>
    // <Modal show={show} onHide={handleClose}>
    //   <ModalHeader>Share </ModalHeader>
    //   <ModalBody>
    //     {/* Facebook share button */}
    //     <FacebookShareButton url={url}>Share on Facebook</FacebookShareButton>

    //     {/* Twitter share button */}
    //     <TwitterShareButton url={url}>Share on Twitter</TwitterShareButton>

    //     {/* WhatsApp share button */}
    //     <WhatsappShareButton url={url}>Share on WhatsApp</WhatsappShareButton>

    //     {/* Email share button */}
    //     <EmailShareButton url={url}>Share via Email</EmailShareButton>

    //     {/* LinkedIn share button */}
    //     <LinkedinShareButton url={url}>Share on LinkedIn</LinkedinShareButton>
    //   </ModalBody>
    //   <ModalFooter>
    //     <Button onClick={handleClose}>Close</Button>
    //   </ModalFooter>
    // </Modal>
  );
};

export default ShareButton;
