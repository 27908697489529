import {
  faFacebook,
  faInstagram,
  faTelegram,
  faTwitter,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, CardHeader, Col, Row } from "react-bootstrap";

const SocialMedia = ({ socialMedia }) => {
  const socialMediaIcons = {
    facebook: faFacebook,
    x: faTwitter,
    youtube: faYoutube,
    whatsapp: faWhatsapp,
    instagram: faInstagram,
    telegram: faTelegram,
    indeed: faInfoCircle,
  };
  return (
    <Card>
      <CardHeader className="green-color fontweigh-700">Follow Us</CardHeader>
      <CardBody>
        <Row>
          {socialMedia?.map((sm) => (
            <Col key={sm.social_media_link} className="my-2">
              <a
                href={sm.social_media_link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-decoration-none"
              >
                <span className="p-2 bg-light rounded-circle ">
                  <FontAwesomeIcon
                    icon={socialMediaIcons[sm.social_media_name?.toLowerCase()]}
                  />
                </span>{" "}
                <span className="fontweigh-500 green-color">
                  {sm.social_media_name}
                </span>
              </a>
            </Col>
          ))}
        </Row>
      </CardBody>
    </Card>
  );
};
export default SocialMedia;
