import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Preloader from "../components/Preloader";
import Sidebar from "../components/Sidebar";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "../routes";
import { useSelector } from "react-redux";
import Progress from "../components/Progress";

const RouteWithSidebar = ({ component: Component, isLoggedIn, ...rest }) => {
  const token = useSelector((state) => state.login.authToken);

  const [authToken, setauthToken] = useState(token);

  const loading = useSelector((state) => state.loading.loading);

  useEffect(() => {
    setauthToken(token);
  }, [token]);

  return (
    <Route
      {...rest}
      render={(props) =>
        authToken ? (
          <>
            <Preloader show={loading} />

            <Sidebar />
            <Navbar />
            {/* <Progress now={0} variant="success" size="xl" loading={loading} /> */}
            <main className="content">
              <Component type={rest.type} {...props} />
            </main>
          </>
        ) : (
          <Redirect to={Routes.Signin.path} />
        )
      }
    />
  );
};
export default RouteWithSidebar;
