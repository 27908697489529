import { Nav, Container } from "react-bootstrap";

import { Link, useParams } from "react-router-dom";
import { Routes } from "../../../routes";

const ClinicDetailsNav = ({ clinic_id }) => {
  const { type, name } = useParams();
  return (
    <Container>
      <Nav className="justify-content-center fontweigh-700  pb-2 " fill>
        <Nav.Item
          as={Link}
          className={`link-text ${
            type === "profile" && "underline"
          } cursor-pointer mb-3 mx-4`}
          to={{
            pathname: `${Routes.clinicDetails.path}/${clinic_id}/${name}/profile`,
          }}
        >
          Profile
        </Nav.Item>
        <Nav.Item
          as={Link}
          className={`link-text ${
            type === "appointment" && "underline"
          } cursor-pointer mb-3 mx-4`}
          to={{
            pathname: `${Routes.clinicDetails.path}/${clinic_id}/${name}/appointment`,
          }}
        >
          Appointment
        </Nav.Item>
      </Nav>
    </Container>
  );
};
export default ClinicDetailsNav;
