import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormCheck,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  TabContainer,
} from "react-bootstrap";

const TabContent = ({
  from,
  setFrom,
  to,
  setTo,
  options,
  saveHandler,
  selectedDay,
  selectedOption,
  setSelectedOption,
  formData,
}) => {
  const [enterHours, setEnterHours] = useState(false);
  // const [lastEnteredHours, setLastEnteredHours] = useState({
  //   from: "",
  //   to: "",
  // });
  // useEffect(() => {
  //   if (!selectedOption) {
  //     setSelectedOption("-");
  //   }
  // }, [selectedOption, setSelectedOption]);

  useEffect(() => {
    setEnterHours(selectedOption?.includes("-"));
  }, [selectedOption, selectedDay]);

  useEffect(() => {
    if (selectedDay) {
      setSelectedOption(formData?.work_hours[selectedDay] || "-");

      // if (formData[selectedDay]) {
      //   const [fromTime, toTime] = formData[selectedDay].split("-");
      //   setFrom(fromTime);
      //   setTo(toTime);
      // } else if (enterHours && lastEnteredHours.from && lastEnteredHours.to) {
      //   setFrom(lastEnteredHours.from);
      //   setTo(lastEnteredHours.to);
      // }
    }
  }, [
    selectedDay,
    formData,
    setSelectedOption,
    // enterHours,
    // lastEnteredHours,
    // setFrom,
    // setTo,
  ]);

  useEffect(() => {
    if (enterHours) {
      if (selectedOption && selectedOption.includes("-")) {
        const [fromTime, toTime] = selectedOption.split("-");
        setFrom(fromTime);
        setTo(toTime);
      }
    } else {
      setFrom("");
      setTo("");
    }
  }, [enterHours, selectedOption, setFrom, setTo]);

  // const handleSave = () => {
  //   saveHandler();
  //   if (enterHours) {
  //     setLastEnteredHours({ from, to });
  //   }
  // };

  return (
    <TabContainer>
      <Row className="mb-2">
        {options.map((option) => (
          <Col key={option.value} className="fontweight-500">
            <FormCheck
              type="radio"
              id={`checkbox-${option.value}`}
              label={option.label}
              checked={
                selectedOption === option.value ||
                (option.value.includes("-") && selectedOption?.includes("-"))
              }
              onChange={() => setSelectedOption(option.value)}
            />
          </Col>
        ))}
      </Row>
      <Row className="align-items-end">
        {enterHours && (
          <>
            <Col lg={3} sm={4}>
              <FormGroup controlId={`from-${selectedDay}`}>
                <FormLabel className="fontweight-500 mb-1">From:</FormLabel>
                <FormControl
                  type="time"
                  step="60"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={3} sm={4}>
              <FormGroup controlId={`to-${selectedDay}`}>
                <FormLabel className="fontweight-500 mb-1">To:</FormLabel>
                <FormControl
                  type="time"
                  value={to}
                  onChange={(e) => setTo(e.target.value)}
                />
              </FormGroup>
            </Col>
          </>
        )}
        <Col className="d-flex justify-content-center mt-4">
          <Button className="green-btn" onClick={saveHandler}>
            Save
          </Button>
        </Col>
      </Row>
    </TabContainer>
  );
};

export default TabContent;
