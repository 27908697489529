import React, { useState, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import noConnection from "../src/assets/img/noConnection.webp";

const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  if (isOnline) {
    return props.children;
  } else {
    return (
      <Container fluid style={styles.container}>
        <Image
          src={noConnection}
          alt="No Internet Connection"
          style={styles.image}
          rounded
        />
        <h2 style={styles.text}>No Internet Connection</h2>
        <p style={styles.subText}>Please check your network and try again.</p>
      </Container>
    );
  }
};
const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: "#f8f9fa",
  },
  image: {
    width: "600px",
    marginBottom: "20px",
  },
  text: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  subText: {
    fontSize: "16px",
    color: "#6c757d",
  },
};
export default NoInternetConnection;
