import { Col, Row } from "react-bootstrap";
import ProductCard from "../Cards/ProductCard";
import { useEffect, useState } from "react";
import axios from "../../axios";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CustomPagination from "../../components/Pagination";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import LoadingPlaceHolder from "./LoadingPlaceHolder";

const DispProds = ({ setShowToast, setSuccess, setToastBody, addToCart }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const { dis_id, id: cat_id } = useParams();

  const fetchProducts = async ({ queryKey }) => {
    const [, dis_id, cat_id, page] = queryKey;
    const res = await axios(
      `products/dispensary/${dis_id}/category/${cat_id}?limit=18&page=${page}`
    );

    return res.data.data.category;
  };
  const {
    data,
    isLoading,
    // error: catError,
  } = useQuery({
    queryKey: ["categorywise-dispensary-products", dis_id, cat_id, currentPage],
    queryFn: fetchProducts,
    // enabled: !!dis_id,
  });

  useEffect(() => {
    dispatch(loadingSet(isLoading));
  }, [dispatch, isLoading]);
  return (
    <>
      {isLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <Row>
          <h1 className="fs-4">{data?.dispensary?.name}</h1>
          <h2 className="fs-5">{data?.name}</h2>
          {data?.dispensary?.products?.map((product) => (
            <Col md={4} lg={3} sm={6}>
              <ProductCard
                setShowToast={setShowToast}
                setToastBody={setToastBody}
                setSuccess={setSuccess}
                product={product}
                dispensary={data?.dispensary}
                addToCart={addToCart}
              />
            </Col>
          ))}
          {data?.totalPages > 1 ? (
            <div className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
              <CustomPagination
                totalPages={data?.totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : (
            ""
          )}
        </Row>
      )}
    </>
  );
};
export default DispProds;
