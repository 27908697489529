import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import CustomToast from "../../reusable/CustomToast";
import axios from "../../axios";

const AddEditReviewReply = ({
  reviewReplyData,
  show,
  handleClose,
  refetchReviews,
  setShowToast,
  setToastBody,
  setSuccess,
}) => {
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    setData(reviewReplyData);
  }, [reviewReplyData]);

  const handleSave = async () => {
    dispatch(loadingSet(true));
    try {
      const res = await axios({
        method: "post",
        url: `/dis/review/update/${reviewReplyData.id}`,
        data: data,
      });
      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchReviews();
      handleClose();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <ModalHeader>
        {reviewReplyData.review_reply ? "Edit Reply" : "Add Reply"}
      </ModalHeader>
      <ModalBody>
        <FormControl
          as="textarea"
          rows="3"
          value={data.owner_reply}
          onChange={(e) =>
            setData((prevData) => ({
              ...prevData,
              owner_reply: e.target.value,
            }))
          }
          placeholder="Reply"
        />
      </ModalBody>
      <ModalFooter>
        <Button
          variant="outline-danger"
          className="fontweigh-500"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button className="green-btn fontweigh-500" onClick={handleSave}>
          Save Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default AddEditReviewReply;
