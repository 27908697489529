import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  faInfoCircle,
  faPlusCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Nav,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
import { valuehandler } from "../../../components/Helpers/HelperFunctions";
import TabContent from "../../../components/Helpers/TabContent";
import DispPlaceAutoComplete from "../../../reusable/Autocomplete/DispPlaceAutoComplete";

const Info = ({
  form,
  setForm,
  errors,
  handleChange,
  socialMedia,
  setSocialMedia,
  setShowToast,
  setSuccess,
  setToastBody,
}) => {
  const [selectedDay, setSelectedDay] = useState("monday");
  const [selectedOption, setSelectedOption] = useState("-");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");

  const smOptions = useMemo(
    () => [
      { label: "Facebook", value: "Facebook" },
      { label: "X", value: "X" },
      { label: "Instagram", value: "Instagram" },
      { label: "Indeed", value: "Indeed" },
      { label: "Youtube", value: "Youtube" },
      { label: "WhatsApp", value: "WhatsApp" },
      { label: "Telegram", value: "Telegram" },
    ],
    []
  );

  const options = useMemo(
    () => [
      { value: "-", label: "Enter Hours" },
      { value: "open_all_day", label: "Open All Day" },
      { value: "closed_all_day", label: "Closed All Day" },
      { value: "by_appointment", label: "By Appointment Only" },
    ],
    []
  );

  const [location, setLocation] = useState({
    street_address: form.street,
    city: form.city,
    state: form.state,
    zip_code: form.postal_code,
    lat: form.latitude,
    long: form.longitude,
  });

  // Update form state when location changes
  useEffect(() => {
    setForm((prevForm) => ({
      ...prevForm,
      street: location.street_address,
      city: location.city,
      state: location.state,
      latitude: location.lat,
      longitude: location.long,
      postal_code: location.zip_code,
    }));
  }, [location, setForm]);

  const handleAddSm = useCallback(() => {
    setSocialMedia((prevSm) => [
      ...prevSm,
      { social_media_name: "", social_media_link: "" },
    ]);
  }, [setSocialMedia]);

  const handleRemoveSm = useCallback(
    (index) => {
      setSocialMedia((prevSm) => prevSm.filter((_, i) => i !== index));
    },
    [setSocialMedia]
  );

  const handleSmChange = useCallback(
    (index, key, newValue) => {
      setSocialMedia((prevSm) => {
        const updatedSm = [...prevSm];
        updatedSm[index][key] = newValue;
        return updatedSm;
      });
    },
    [setSocialMedia]
  );

  const saveHandler = () => {
    if (selectedOption === "-" && (!from || !to)) {
      setShowToast(true);
      setToastBody(`Please enter both "From" and "To" times`);
      setSuccess(false);
      return;
    } else if (selectedOption === "-" && from > to) {
      setShowToast(true);
      setToastBody(`"From" time should be less than "To" time`);
      setSuccess(false);
      return;
    }

    setForm((prevForm) => ({
      ...prevForm,
      work_hours: {
        ...prevForm.work_hours,
        [selectedDay]:
          selectedOption === "-" ? `${from}-${to}` : selectedOption,
      },
    }));
    setShowToast(true);
    setToastBody(`Work hours saved for ${selectedDay}`);
    setSuccess(true);
    const dayIndex = Object.keys(workingHours).indexOf(selectedDay);
    if (dayIndex >= 0 && dayIndex < Object.keys(workingHours).length - 1) {
      setSelectedDay(Object.keys(workingHours)[dayIndex + 1]);
    }
  };

  const workingHours = useMemo(
    () => ({
      monday: form.work_hours?.monday || "",
      tuesday: form.work_hours?.tuesday || "",
      wednesday: form.work_hours?.wednesday || "",
      thursday: form.work_hours?.thursday || "",
      friday: form.work_hours?.friday || "",
      saturday: form.work_hours?.saturday || "",
      sunday: form.work_hours?.sunday || "",
    }),
    [form.work_hours]
  );

  return (
    <Container fluid className="my-4">
      <h3 className="text-decoration-underline text-center mb-4">
        CONTACT INFO
      </h3>
      <Row>
        <Col lg={3} md={4} sm={6} className="mb-3">
          <FormGroup id="Website">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Enter website URL of the dispensary</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Website <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              type="text"
              name="website"
              value={form.website}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="Website"
            />
          </FormGroup>
        </Col>
        <Col lg={3} md={4} sm={6} className="mb-3">
          <FormGroup id="contact_email">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Enter the Email ID of the dispensary</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Email<span className="text-danger">*</span>{" "}
                <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              type="email"
              name="contact_email"
              value={form.contact_email}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="Email"
            />
            <p className="text-danger">{errors.contact_email}</p>
          </FormGroup>
        </Col>
        <Col lg={3} md={4} sm={6} className="mb-3">
          <FormGroup id="Phone Number">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Enter the phone number of the dispensary</Tooltip>
              }
            >
              <FormLabel className="fontweigh-500 mb-1">
                Phone Number<span className="text-danger">*</span>{" "}
                <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              required
              type="text"
              name="phone_number"
              value={form.phone_number}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="Phone Number"
            />
            <p className="text-danger">{errors.phone_number}</p>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {socialMedia.map((sm, index) => (
          <Row key={index}>
            <Col lg={3} md={4} sm={5} className="mb-3">
              <FormGroup>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip>
                      Enter social media platform of the dispensary
                    </Tooltip>
                  }
                >
                  <FormLabel className="fontweigh-500 mb-1">
                    Social Media <FontAwesomeIcon icon={faInfoCircle} />
                  </FormLabel>
                </OverlayTrigger>
                <Select
                  placeholder="Enter Social Media"
                  value={valuehandler(smOptions, sm.social_media_name)}
                  options={smOptions}
                  onChange={(e) =>
                    handleSmChange(index, "social_media_name", e.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col lg={6} md={8} sm={7} className="mb-3 d-flex align-items-end">
              <FormGroup>
                <FormLabel className="fontweigh-500 mb-1">
                  Social Media Link
                </FormLabel>
                <FormControl
                  type="text"
                  placeholder="Enter social media link"
                  value={sm.social_media_link}
                  onChange={(e) =>
                    handleSmChange(index, "social_media_link", e.target.value)
                  }
                />
              </FormGroup>
              {socialMedia.length > 1 && (
                <FontAwesomeIcon
                  icon={faTrash}
                  className="ms-4 text-danger mb-3 mx-3"
                  onClick={() => handleRemoveSm(index)}
                  style={{ cursor: "pointer" }}
                />
              )}
              <FontAwesomeIcon
                icon={faPlusCircle}
                className="ms-2 green-color fs-4 mb-2"
                onClick={handleAddSm}
                style={{ cursor: "pointer" }}
              />
            </Col>
          </Row>
        ))}
      </Row>
      <hr />
      <h3 className="mt-2 text-decoration-underline text-center my-4">
        WORK HOURS
      </h3>
      {/* <Container> */}
      <Row className="mb-4">
        <Col>
          <Nav
            fill
            variant="tabs"
            onSelect={(day) => setSelectedDay(day)}
            defaultActiveKey="monday"
            activeKey={selectedDay}
          >
            {Object.keys(workingHours).map((day) => (
              <Nav.Item key={day}>
                <Nav.Link eventKey={day}>
                  {day.charAt(0).toUpperCase() + day.slice(1)}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <TabContent
            selectedDay={selectedDay}
            setSelectedDay={setSelectedDay}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            options={options}
            saveHandler={saveHandler}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            formData={form}
          />
        </Col>
      </Row>
      <hr />
      <h4 className="my-3 text-decoration-underline">Location</h4>
      <Row>
        <Col lg={3} md={4} className="mb-3">
          <FormGroup id="street">
            <FormLabel className="fontweigh-500 mb-1">
              Street<span className="text-danger">*</span>
            </FormLabel>
            <DispPlaceAutoComplete
              setNewLocation={setLocation}
              newLocation={location}
            />
            <p className="text-danger">{errors.street}</p>
          </FormGroup>
        </Col>
        <Col lg={3} md={4} className="mb-3">
          <FormGroup id="City">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Enter dispensary city</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                City <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              type="text"
              name="city"
              value={form.city}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="City"
            />
          </FormGroup>
        </Col>
        <Col lg={3} md={4} className="mb-3">
          <FormGroup id="State">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Enter dispensary state</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                State<span className="text-danger">*</span>{" "}
                <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              type="text"
              name="state"
              value={form.state}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="State"
            />
            <p className="text-danger">{errors.state}</p>
          </FormGroup>
        </Col>
        <Col lg={3} md={4} className="mb-3">
          <FormGroup id="Postal code">
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Enter zip code of the dispensary</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Zip Code <span className="text-danger">*</span>{" "}
                <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              type="text"
              name="postal_code"
              value={form.postal_code}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="Zip Code"
            />
            <p className="text-danger">{errors.postal_code}</p>
          </FormGroup>
        </Col>
      </Row>
      {/* </Container> */}
    </Container>
  );
};

export default React.memo(Info);
