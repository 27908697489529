import { StandaloneSearchBox } from "@react-google-maps/api";
import { useRef, useState, useEffect } from "react";

const PlaceAutocomplete = ({ setNewLocation, newLocation, type }) => {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (newLocation) {
      if (type === "state") {
        setInputValue(newLocation.state || "");
      } else if (type === "house_number") {
        setInputValue(newLocation.address_line1 || "");
      } else if (type === "city") {
        setInputValue(newLocation.city || "");
      }
    }
  }, [newLocation, type]);

  const handlePlaceChange = () => {
    const [place] = inputRef.current.getPlaces();
    if (place) {
      const address = place.address_components;
      const address_line1 =
        address.find((ad) => ad.types.includes("street_number"))?.long_name ||
        "";
      const address_line2 =
        address.find((ad) => ad.types.includes("route"))?.long_name || "";
      const city =
        address.find((ad) => ad.types.includes("locality"))?.long_name || "";
      const state =
        address.find((ad) => ad.types.includes("administrative_area_level_1"))
          ?.long_name || "";
      const zip_code =
        address.find((ad) => ad.types.includes("postal_code"))?.long_name || "";
      const country =
        address.find((ad) => ad.types.includes("country"))?.short_name || "";
      const lat = place.geometry.location.lat();
      const long = place.geometry.location.lng();

      // Update newLocation only when a selection is confirmed
      setNewLocation({
        address_line1,
        address_line2,
        city,
        state,
        country,
        lat,
        long,
        zip_code,
      });
    }
  };

  return (
    <div className="mb-2 me-2">
      <StandaloneSearchBox
        onLoad={(ref) => (inputRef.current = ref)}
        onPlacesChanged={handlePlaceChange}
      >
        <input
          type="text"
          className="form-control"
          placeholder="Enter Location"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </StandaloneSearchBox>
    </div>
  );
};

export default PlaceAutocomplete;
