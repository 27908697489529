import { useHistory } from "react-router-dom";
import moment from "moment";
import { Routes } from "../routes";
import useNotifications from "./useNotifications";

const useNotificationActions = () => {
  const history = useHistory();
  const { markAsRead } = useNotifications();

  const handleNotificationClick = (notification) => {
    markAsRead(notification.id);
    // Redirect based on the type of notification
    switch (notification.type) {
      case "App\\Notifications\\AdvertisementLeadNotification":
        history.push(Routes.advertisementLeads.path);
        break;
      case "App\\Notifications\\OrderNotification":
        history.push(Routes.Orders.path);
        break;
      case "App\\Notifications\\MedicalCardLeadNotification":
        history.push(Routes.omcLeads.path);
        break;
      case "App\\Notifications\\ReviewNotification":
        history.push(Routes.Reviews.path);
        break;
      case "App\\Notifications\\PartnerClaimNotification":
        history.push(Routes.claimedLead.path);
        break;
      default:
        break;
    }
  };

  const notificationTime = (notificationTime) => {
    const notfTime = moment(notificationTime);
    const now = moment();
    const diffInMinutes = now.diff(notfTime, "minutes");
    const diffInHours = now.diff(notfTime, "hours");
    const diffInDays = now.diff(notfTime, "days");
    const diffInMonths = now.diff(notfTime, "months");

    if (diffInMinutes < 60) {
      return `${diffInMinutes} minutes ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours} hours ago`;
    } else if (diffInDays <= 30) {
      return `${diffInDays} days ago`;
    } else {
      return `${diffInMonths} months ago`;
    }
  };

  return {
    handleNotificationClick,
    notificationTime,
  };
};

export default useNotificationActions;
