// components

import Preloader from "../components/Preloader";
import { Route } from "react-router-dom";
import MainNavbar from "../components/Navbar";
import Footer from "../pages/Website/Footer";
import { useSelector } from "react-redux";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const loading = useSelector((state) => state.loading.loading);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <MainNavbar /> <Preloader show={loading} /> <Component {...props} />
          <Footer />
        </>
      )}
    />
  );
};
export default RouteWithLoader;
