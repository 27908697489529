import { combineReducers } from "redux";
import loginReducer from "./LogIn/loginSlice";
import loadingReducer from "./loadingSlice";
import homePageReducer from "./LogIn/homePageSlice";
import cartReducer from "./cartSlice";
import apiReducer from "./apiSlice";
import planReducer from "./partnerPlanSlice";
const rootReducer = combineReducers({
  login: loginReducer,
  loading: loadingReducer,
  homePage: homePageReducer,
  userCart: cartReducer,
  api: apiReducer,
  plan: planReducer,
});
export default rootReducer;
