import React, { useState } from "react";
import SimpleBar from "simplebar-react";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartPie,
  faTimes,
  faBuilding,
  faStore,
  faCannabis,
  faChartBar,
  faMoneyBill,
  faBlog,
  faClipboard,
  faUser,
  faShoppingCart,
  faPercentage,
  faAd,
  faStar,
  faHandshake,
  faHandHoldingMedical,
  faQuestion,
  faEnvelope,
  faClinicMedical,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, Image, Button, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import { Routes } from "../routes";
import logo from "../assets/img/wtmIcon.png";

import { useSelector } from "react-redux";
import NavItem from "./NavItem";

export default () => {
  const [show, setShow] = useState(false);
  const userData = useSelector((state) => state.login.userData);
  const roleId = userData.role_id;
  const showClass = show ? "show" : "";

  const onCollapse = () => setShow((prevState) => !prevState);

  return (
    <>
      <Navbar
        expand={false}
        collapseOnSelect
        variant="dark"
        className="navbar-theme-primary px-4 d-md-none"
        style={{ zIndex: "2" }}
      >
        <Navbar.Brand
          className="me-lg-5"
          as={Link}
          to={Routes.AdminDashboard.path}
        >
          <Image src={logo} alt="logo" className="navbar-brand-light" />
        </Navbar.Brand>
        <Navbar.Toggle
          as={Button}
          aria-controls="main-navbar"
          onClick={onCollapse}
        >
          <span className="navbar-toggler-icon" />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames="sidebar-transition">
        <SimpleBar
          className={`collapse ${showClass} sidebar d-md-block bg-primary text-white`}
        >
          <div className="sidebar-inner px-4 pt-3">
            <div className="user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4">
              <div className="d-flex align-items-center">
                <div className="user-avatar lg-avatar me-4">
                  <Image
                    src={userData.profile_pic}
                    alt="profile logo"
                    className="card-img-top rounded-circle border-white"
                  />
                </div>
                <div className="d-block">
                  <h6>Hi, {userData.name}</h6>
                </div>
              </div>
              <Nav.Link className="collapse-close " onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className="flex-column pt-3 pt-md-0">
              <NavItem
                title="WeedToMe"
                link={Routes.Home.path}
                image={logo}
                setShow={setShow}
              />
              <NavItem
                title="Overview"
                link={Routes.AdminDashboard.path}
                icon={faChartPie}
                setShow={setShow}
              />
              <NavItem
                title="States"
                icon={faBuilding}
                link={Routes.States.path}
                setShow={setShow}
              />
              {roleId === 1 && (
                <NavItem
                  title="Product Types"
                  icon={faCannabis}
                  link={Routes.ProductTypes.path}
                  setShow={setShow}
                />
              )}
              <NavItem
                title="Products"
                icon={faCannabis}
                link={Routes.Products.path}
                setShow={setShow}
              />
              {roleId === 1 && (
                <NavItem
                  title="Dispensary Types"
                  icon={faStore}
                  link={Routes.DispensaryTypes.path}
                  setShow={setShow}
                />
              )}
              <NavItem
                title="Dispensaries"
                icon={faStore}
                link={Routes.Dispensaries.path}
                setShow={setShow}
              />

              <NavItem
                title="Brands"
                icon={faStar}
                link={Routes.brands.path}
                setShow={setShow}
              />
              <NavItem
                title="Orders"
                icon={faShoppingCart}
                link={Routes.Orders.path}
                setShow={setShow}
              />
              {roleId === 1 && (
                <NavItem
                  title="Coupons"
                  icon={faMoneyBill}
                  link={Routes.Coupons.path}
                  setShow={setShow}
                />
              )}
              {roleId === 1 && (
                <NavItem
                  title="Advertisement Leads"
                  icon={faAd}
                  link={Routes.advertisementLeads.path}
                  setShow={setShow}
                />
              )}
              <NavItem
                title="Advertisement"
                icon={faAd}
                link={Routes.advertisement.path}
                setShow={setShow}
              />
              {roleId === 1 && (
                <NavItem
                  title="Claimed Lead"
                  icon={faHandshake}
                  link={Routes.claimedLead.path}
                  setShow={setShow}
                />
              )}
              <NavItem
                title="Deals"
                icon={faPercentage}
                link={Routes.Deals.path}
                setShow={setShow}
              />
              {roleId === 2 && (
                <NavItem
                  title="Billing List"
                  icon={faClipboard}
                  link={Routes.billingList.path}
                  setShow={setShow}
                />
              )}
              {roleId === 1 && (
                <NavItem
                  title="Plans"
                  icon={faChartBar}
                  link={Routes.Plans.path}
                  setShow={setShow}
                />
              )}
              {roleId === 1 && (
                <NavItem
                  title="OMC Leads"
                  icon={faHandHoldingMedical}
                  link={Routes.omcLeads.path}
                  setShow={setShow}
                />
              )}
              {roleId === 1 && (
                <NavItem
                  title="MMJ Doctors"
                  icon={faClinicMedical}
                  link={Routes.clinics.path}
                  setShow={setShow}
                />
              )}
              {roleId === 1 && (
                <NavItem
                  title="Blog Types"
                  icon={faBlog}
                  link={Routes.BlogTypes.path}
                  setShow={setShow}
                />
              )}
              <NavItem
                title="Blogs"
                icon={faBlog}
                link={Routes.panelBlogs.path}
                setShow={setShow}
              />

              {/* {roleId === 1 && ( */}
              <NavItem
                title="Reviews"
                icon={faClipboard}
                link={Routes.Reviews.path}
                setShow={setShow}
              />
              {roleId === 1 && (
                <NavItem
                  title="Blog Comments"
                  icon={faClipboard}
                  link={Routes.blogComments.path}
                  setShow={setShow}
                />
              )}
              {/* FAQ */}
              {roleId === 1 && (
                <NavItem
                  title="FAQ"
                  icon={faQuestion}
                  link={Routes.faq.path}
                  setShow={setShow}
                />
              )}
              {/* )} */}
              {roleId === 1 && (
                <NavItem
                  title="Send Mail"
                  icon={faEnvelope}
                  link={Routes.emailMarketing.path}
                  setShow={setShow}
                />
              )}
              {roleId === 1 && (
                <NavItem
                  title="Users"
                  icon={faUser}
                  link={Routes.Users.path}
                  setShow={setShow}
                />
              )}
            </Nav>
          </div>
        </SimpleBar>
      </CSSTransition>
    </>
  );
};
