import axios from "../../axios";
import { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";

import CustomToast from "../../reusable/CustomToast";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import {
  handleChange,
  yesNoOptions,
} from "../../components/Helpers/HelperFunctions";

const AddEditReviews = ({
  show,
  handleClose,
  type,
  reviewData,
  refetchReviews,
}) => {
  const [formData, setFormData] = useState({
    dis_id: "",
    rating: "",
    comment: "",
    status: 0,
  });
  const [error, setError] = useState({
    rating: "",
    comment: "",
    dis_id: "",
  });
  const [dispensaries, setDispensaries] = useState([]);
  //toast states
  const [toastBody, setToastBody] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(true);

  //dispatch
  const dispatch = useDispatch();

  //useEffects

  useEffect(() => {
    if (type) {
      setFormData({ ...reviewData });
    }
    getDispensaries();
    refetchReviews();
  }, [reviewData]);

  //Functions
  const handleSave = async () => {
    const requiredFields = ["rating", "dis_id", "comment"];
    let newErrors = {};
    requiredFields.forEach((field) => {
      if (!formData[field]) {
        newErrors[field] = `*${field.replace("_", " ")} is mandatory`;
      }
    });
    setError(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setShowToast(true);
      setToastBody("Please fill all the mandatory fields");
      setSuccess(false);
    }
    // If there are no errors, proceed with form submission
    if (Object.keys(newErrors).length === 0) {
      dispatch(loadingSet(true));
      const url = type
        ? `/dis/review/update/${reviewData.id}`
        : "/dis/review/add";

      try {
        const res = await axios({
          method: "post",
          url,
          data: formData,
        });

        setShowToast(true);
        setToastBody(res.data.message);
        setSuccess(true);
        refetchReviews();
        handleClose();
      } catch (err) {
        setShowToast(true);
        setToastBody(err.response.data.message);
        setSuccess(false);
      } finally {
        dispatch(loadingSet(false));
      }
    }
  };

  const valuehandler = (arr, value) => arr.find((opt) => opt.value === value);

  const getDispensaries = () => {
    axios("/dispensaries")
      .then((res) => {
        setDispensaries(res.data.data.dispensaries);
      })
      .catch((err) => alert(err.message));
  };

  const ratingOptions = [
    { label: "1 Star", value: 1 },
    { label: "2 Star", value: 2 },
    { label: "3 Star", value: 3 },
    { label: "4 Star", value: 4 },
    { label: "5 Star", value: 5 },
  ];
  const dispensaryOptions = dispensaries.map((disp) => ({
    label: disp.name,
    value: disp.id,
  }));
  return (
    <>
      <Modal show={show} size="xl" onHide={handleClose}>
        <ModalHeader closeButton>
          <Modal.Title>{type ? "Edit Review" : "Add New Review"}</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={3} md={4} className="mb-3">
              <FormGroup id="rating">
                <FormLabel className="fontweigh-500">
                  Ratings<span className="text-danger">*</span>
                </FormLabel>
                <Select
                  name="rating"
                  placeholder="Ratings"
                  value={valuehandler(ratingOptions, formData.rating)}
                  options={ratingOptions}
                  onChange={(data) =>
                    setFormData({ ...formData, rating: data.value })
                  }
                />{" "}
                <p className="text-danger">{error.rating}</p>
              </FormGroup>
            </Col>
            <Col lg={3} md={4} className="mb-3">
              <FormGroup id="Category">
                <FormLabel className="fontweigh-500">
                  Dispensary<span className="text-danger">*</span>
                </FormLabel>
                <Select
                  name="dis_id"
                  placeholder="Select Dispensary"
                  value={valuehandler(dispensaryOptions, formData.dis_id)}
                  options={dispensaryOptions}
                  onChange={(data) =>
                    setFormData({ ...formData, dis_id: data.value })
                  }
                />{" "}
                {error.dis_id && (
                  <p className="text-danger">Dispensary is mandatory</p>
                )}
              </FormGroup>
            </Col>

            <Col lg={3} md={4} className="mb-3">
              <FormGroup id="status">
                <FormLabel className="fontweigh-500">Is Active</FormLabel>
                <Select
                  name="status"
                  placeholder="Status"
                  value={valuehandler(yesNoOptions, formData.status)}
                  options={yesNoOptions}
                  onChange={(data) =>
                    setFormData({ ...formData, status: data.value })
                  }
                />
              </FormGroup>
            </Col>

            <Col lg={3} md={4}>
              <FormGroup id="Description">
                <FormLabel className="fontweigh-500">
                  Comment<span className="text-danger">*</span>
                </FormLabel>
                <FormControl
                  required
                  as="textarea"
                  rows="3"
                  name="comment"
                  value={formData.comment}
                  onChange={(e) => handleChange(e, setFormData)}
                  placeholder="Comment"
                />
                <p className="text-danger">{error.comment}</p>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-danger"
            className="fontweigh-500"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button className="green-btn fontweigh-500" onClick={handleSave}>
            Save Changes
          </Button>
        </ModalFooter>
      </Modal>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};
export default AddEditReviews;
