import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Banner from "../Home/Sections/Banner";
import axios from "../../../axios";
import { useDispatch } from "react-redux";

import { useParams } from "react-router-dom";

// import DispensaryClinicCard from "../../../components/DispensaryClinicCard";
import Actions from "../Dispensary/Actions";
import ClinicDetailsNav from "./ClinicDetailsNav";
import ClinicDetailsBody from "./ClinicDetailBody";
import NotFound from "../../Login/NotFound";
import ServerError from "../../Login/ServerError";
import CustomToast from "../../../reusable/CustomToast";

// API calls
const fetchClinicDetails = async ({ queryKey }) => {
  const [, id, filter] = queryKey;
  const { data } = await axios.get(`/mmjclinics?id=${id}`);
  return data.data.mmj_clinics[0];
};

// const fetchRelatedDispensaries = async (id) => {
//   const { data } = await axios.get(`/related/dispensary/${id}`);
//   return data.data;
// };

const ClinicDetails = () => {
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);
  const [filter, setFilter] = useState({
    profile: true,
    appointment: false,
  });

  const dispatch = useDispatch();
  const { type, id, link_via_email } = useParams();
  const linkViaEmail = link_via_email === "nofity-via-Email" ? true : false;
  // Update filter when type changes
  useEffect(() => {
    if (type) {
      updateFilter(type);
    }
  }, [type]);

  const updateFilter = (type) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      profile: type === "profile",
      appointment: type === "appointment",
    }));
  };

  // Fetch dispensary details
  const {
    data: clinicData,
    isLoading: isLoadingClinic,
    error: errorClinic,
    refetch: refetchClinicDetails,
  } = useQuery({
    queryKey: ["clinic-details", id, filter],
    queryFn: fetchClinicDetails,
    enabled: !!id,
  });
  // Fetch related dispensaries
  //   const {
  //     data: relatedDispensaries,
  //     isLoading: isLoadingRelated,
  //     error: errorRelated,
  //     refetch: refetchRelatedDispensaries,
  //   } = useQuery({
  //     queryKey: ["relatedDispensaries", id],
  //     queryFn: () => fetchRelatedDispensaries(id),
  //     enabled: !!id, // Only run if id exists
  //   });

  // Count dispensary view
  //   useEffect(() => {
  //     if (id) {
  //       logDispensaryCount(id, linkViaEmail);
  //     }
  //   }, [id, linkViaEmail]);

  // Update loading state based on any API status
  //   useEffect(() => {
  //     const loading = isLoadingDispensary || isLoadingCount || isLoadingRelated;
  //     dispatch(loadingSet(loading));
  //   }, [isLoadingDispensary, isLoadingCount, isLoadingRelated, dispatch]);

  if (errorClinic) {
    const error = errorClinic;

    // Check if it's a 404 error (Not Found)
    if (error.response?.status === 404) {
      return <NotFound />;
    }
    return <ServerError error={error} />;
  }
  return (
    <>
      <Banner
        coverImage={clinicData?.cover_image}
        logo={clinicData?.logo}
        name={clinicData?.name}
        refetchDisp={refetchClinicDetails}
        setShowToast={setShowToast}
        setToastBody={setToastBody}
        setSuccess={setSuccess}
        type="doctors"
      />

      <ClinicDetailsNav clinic_id={id} />

      <Actions
        mailId={clinicData?.contact_email}
        phoneNum={clinicData?.phone_number}
        // plan_id={clinicData?.plan_id}
      />
      {/* <br />
      <br /> */}
      {clinicData && (
        <ClinicDetailsBody
          clinicData={clinicData}
          //   averageRating={detailsCount?.average_rating}
          getDispensaryDetails={refetchClinicDetails}
        />
      )}
      <br />
      <br />
      <br />
      <br />
      <br />
      {/* {relatedDispensaries?.length ? (
        <Container fluid>
          <h2 className="fw-bold">Related Dispensaries</h2>
          <Row>
            {relatedDispensaries.map((disp) => (
              <Col lg={3} md={4} sm={6} key={disp.id}>
                <DispensaryClinicCard
                  data={disp}
                  refetchData={refetchRelatedDispensaries}
                  setShowToast={setShowToast}
                  setToastBody={setToastBody}
                  setSuccess={setSuccess}
                />
              </Col>
            ))}
          </Row>
        </Container>
      ) : (
        ""
      )} */}
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};

export default ClinicDetails;
