import { useState, useEffect } from "react";
import dummy_cover_image from "../../assets/img/dummy_cover_image.webp";
import tableViewHeroimage from "../../assets/img/tablet_view_hero.webp";
import mobileViewHeroImage from "../../assets/img/mobile_view_hero.webp";

export default function LoadingPlaceHolder({ height, image, width }) {
  const [backgroundImage, setBackgroundImage] = useState(dummy_cover_image);

  useEffect(() => {
    const updateImage = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth >= 600 && screenWidth < 900) {
        // `md` breakpoint for tablets
        setBackgroundImage(tableViewHeroimage);
      } else if (screenWidth < 600) {
        setBackgroundImage(mobileViewHeroImage);
      } else {
        setBackgroundImage(dummy_cover_image);
      }
    };

    // Add event listener for resizing
    window.addEventListener("resize", updateImage);

    // Set initial image on mount
    updateImage();

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener("resize", updateImage);
    };
  }, []);

  return (
    <>
      {image ? (
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
            height: `${height}px`,
            width: `${width}%`,
            margin: "auto",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* Image placeholder */}
        </div>
      ) : (
        <div
          style={{ height: `${height}px` }}
          className="loading-data fs-5 mt-4"
        >
          Loading Data...
        </div>
      )}
    </>
  );
}
